import React from 'react';
import { Image } from 'rebass';

const RestaurantLogo = ({ imageUrl, size }) => (
  <Image
    alt="restaurant logo"
    p={1}
    style={{ border: '1px solid #ddd', borderRadius: '50%' }}
    width={size}
    height={size}
    src={imageUrl}
  />
);

export default RestaurantLogo;
