import {
  format,
  isAfter,
  isWithinInterval,
  parse,
  differenceInMinutes,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  addDays,
  toDate,
} from 'date-fns';
import { Colors } from './theme';

export const sortByDistance = (a, b) => a.distance - b.distance;
export const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const sortByImageUrl = (a, b) => {
  if (a.imageUrl < b.imageUrl) {
    return -1;
  }
  if (a.imageUrl > b.imageUrl) {
    return 1;
  }
  return 0;
};

export const filterByFav = favouriteIds => r => {
  if (favouriteIds.includes(r.id)) {
    return true;
  }
  return false;
};

export const sortByPrice = (a, b) => {
  if (a.price && !b.price) {
    return -1;
  }
  if (!a.price && b.price) {
    return 1;
  }
  return 0;
};

export const sortDessertLast = (a, b) => {
  const aIncluded = a.Tags.map(t => t.name).includes('Dessert');
  const bIncluded = b.Tags.map(t => t.name).includes('Dessert');
  if (aIncluded && !bIncluded) {
    return 1;
  }
  if (!aIncluded && bIncluded) {
    return -1;
  }
  return 0;
};

export const sortBreakfastLast = (a, b) => {
  const aIncluded = a.Tags.map(t => t.name).includes('Colazione');
  const bIncluded = b.Tags.map(t => t.name).includes('Colazione');
  if (aIncluded && !bIncluded) {
    return 1;
  }
  if (!aIncluded && bIncluded) {
    return -1;
  }
  return 0;
};

export const sortBreakfastFirst = (a, b) => {
  const aIncluded = a.Tags.map(t => t.name).includes('Colazione');
  const bIncluded = b.Tags.map(t => t.name).includes('Colazione');
  if (!aIncluded && bIncluded) {
    return 1;
  }
  if (aIncluded && !bIncluded) {
    return -1;
  }
  return 0;
};

export const composeComparisons = cmpFunctions => (a, b) => {
  for (let i = 0, result; i < cmpFunctions.length; i += 1) {
    result = cmpFunctions[i](a, b);
    if (result) {
      return result;
    }
  }
  return 0;
};

const GOOGLE_MAPS_API = 'AIzaSyCCfqjwhAnUSsYCaUbCfQhHXVY_Aa2XcIc';
export const generateMapsUrl = (lat, lng) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=15&scale=2&size=${Math.round(
    200,
  )}x300&maptype=roadmap&markers=color:red|${lat},${lng}&key=${GOOGLE_MAPS_API}`;

export function calculateDistance(lat1, lon1, lat2, lon2) {
  if (!lat2 || !lon2) {
    return 0;
  }
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function formatDistance(d) {
  if (d * 1000 > 1000) {
    return `${d.toFixed(1)}km`;
  }
  return `${Math.round(d * 1000)}m`;
}

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const DAYS_TEXT = [
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
  'Domenica',
];
export const formatTime = time => {
  const start = `${time.Start.slice(0, 2)}:${time.Start.slice(2, 4)}`;
  const end = `${time.Finish.slice(0, 2)}:${time.Finish.slice(2, 4)}`;
  return `${start} - ${end}`;
};

// Aperto ora
// < 1h apre in 30 min
// > 1h apre alle xx:xx
// Chiuso oggi
export function formattedOpenClose(tradingHours, now = new Date()) {
  const dateToCheck = format(now, 'EEEE');
  if (
    !tradingHours ||
    !tradingHours[dateToCheck] ||
    tradingHours[dateToCheck].length === 0
  ) {
    return {
      color: Colors.RED,
      string: 'Chiuso oggi',
    };
  }
  let result;
  if (tradingHours[dateToCheck] && tradingHours[dateToCheck].length > 0) {
    for (let i = 0; i < tradingHours[dateToCheck].length; i += 1) {
      const openRange = tradingHours[dateToCheck][i];
      const timeToCheck = new Date(now);
      let openStart = parse(openRange.Start, 'HHmm', new Date());
      let openEnd = parse(openRange.Finish, 'HHmm', new Date());
      openStart = setHours(
        setMinutes(now, getMinutes(openStart)),
        getHours(openStart),
      );
      openEnd = setHours(
        setMinutes(now, getMinutes(openEnd)),
        getHours(openEnd),
      );
      if (parseInt(openRange.Finish, 10) < parseInt(openRange.Start, 10)) {
        openEnd = addDays(openEnd, 1);
      }
      if (
        isWithinInterval(timeToCheck, {
          start: toDate(openStart),
          end: toDate(openEnd),
        })
      ) {
        result = {
          color: Colors.GREEN,
          string: 'Aperto',
        };
        break;
      }
      // it's not open let's see if it opens later
      if (!isAfter(now, openEnd)) {
        if (differenceInMinutes(openStart, now) > 59) {
          result = {
            color: Colors.ORANGE,
            string: `Apre alle ${format(openStart, 'HH:mm')}`,
          };
        } else {
          result = {
            color: Colors.ORANGE,
            string: `Apre tra ${differenceInMinutes(openStart, now)} min`,
          };
        }
        break;
      } else {
        result = {
          color: Colors.RED,
          string: 'Chiuso',
        };
      }
    }
  }
  return result;
}
