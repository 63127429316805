import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from 'rebass';
import { MdAccessTime } from 'react-icons/md';
import { formattedOpenClose } from '../utils';
import { Colors } from '../theme';
import { AiOutlineWarning } from 'react-icons/ai';

const RestaurantOpenClose = ({ tradingHours, withIcon = true, ...props }) => {
  const [color, setColor] = useState(null);
  const [string, setString] = useState(null);
  useEffect(() => {
    try {
      const { color: c, string: s } = formattedOpenClose(
        tradingHours,
        new Date(),
      );
      setColor(c);
      setString(s);
    } catch (err) {
      //
    }
  }, [tradingHours]);
  if (!color || !string) {
    return null;
  }
  return (
    <Flex flexDirection="row" alignItems="center">
      {withIcon && (
        <MdAccessTime
          style={{ marginRight: 5 }}
          name="android-time"
          size={20}
          color={Colors.BLACK}
        />
      )}
      <Text
        fontSize={[1, 2]}
        lineHeight={1.4}
        color={color}
        fontWeight="600"
        {...props}
      >
        {string}
      </Text>
    </Flex>
  );
};

RestaurantOpenClose.propTypes = {
  tradingHours: PropTypes.shape().isRequired,
};

export default RestaurantOpenClose;
