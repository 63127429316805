import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import { formatDistance, calculateDistance } from '../utils';
import { Colors } from '../theme';
import UserLocationContext from '../context/UserLocationContext';

const Distance = ({ restaurantLocation, ...props }) => {
  const { latitude, longitude } = useContext(UserLocationContext);
  if (!latitude || !longitude) {
    return null;
  }
  const distance = calculateDistance(
    restaurantLocation.coordinates[1],
    restaurantLocation.coordinates[0],
    latitude,
    longitude,
  );

  return (
    <Text lineHeight={1.4} mr={1} color={Colors.GREY} fontSize={[1, 2]} {...props}>
      {formatDistance(distance)}
    </Text>
  );
};

Distance.propTypes = {
  restaurantLocation: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  }).isRequired,
};

export default Distance;
