import React, { useState, useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Flex, Box, Text, Card, Button, Heading } from 'rebass';
import slugify from 'slugify';
import { isSameDay, parse } from 'date-fns';
import { AiOutlineWarning } from 'react-icons/ai';
import Layout from '../components/Layout';
import RestaurantLogo from '../components/RestaurantLogo';
import { Colors } from '../theme';

import { sortByName, sortByDistance, calculateDistance } from '../utils';
import useLocateMe from '../components/useLocateMe';
import LocateMeButton from '../components/LocateMeButton';
import SEO from '../components/SEO';
import Distance from '../components/Distance';
import UserLocationContext from '../context/UserLocationContext';
import RestaurantOpenClose from '../components/RestaurantOpenClose';

const imgLugano = require('../images/lugano_narrow.jpg');

const holidays = [
  '2019-12-25',
  '2019-12-26',
  '2020-01-01',
  '2020-01-06',
  '2020-03-19',
  '2020-04-13',
  '2020-05-01',
  '2020-05-21',
  '2020-06-01',
  '2020-06-11',
  '2020-06-29',
  '2020-08-01',
  '2020-08-15',
  '2020-11-01',
  '2020-12-08',
  '2020-12-25',
  '2020-12-26',
];

const RestaurantsPage = ({ data }) => {
  const [sortBy, setSortBy] = useState(0);
  const [isHoliday, setIsHoliday] = useState(false);
  // const [address, setAddress] = useState('');
  const { latitude, longitude } = useContext(UserLocationContext);
  const [loading, locateUser] = useLocateMe();
  const restaurants =
    (data && data.mangiami && data.mangiami.listRestaurants) || [];
  const restaurantsWithDistance = restaurants.map(r => {
    const restaurant = { ...r };
    restaurant.distance = calculateDistance(
      r.location.coordinates[1],
      r.location.coordinates[0],
      latitude,
      longitude,
    );
    return restaurant;
  });
  const sortedRestaurants =
    sortBy === 0
      ? restaurantsWithDistance.sort(sortByName)
      : restaurantsWithDistance.sort(sortByDistance);
  useEffect(() => {
    if (
      holidays.some(day =>
        isSameDay(parse(day, 'yyyy-MM-dd', new Date()), new Date()),
      )
    ) {
      setIsHoliday(true);
    }
  }, []);
  return (
    <Layout headerRight="categories">
      <SEO
        pageTitle="Scopri tutti i ristoranti - Mangiami"
        pageDescription="Lasciati ispirare da centinaia di ristoranti e migliaia di foto di piatti e drinks accompagnate da descrizioni e prezzi per decidere cosa mangiare."
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl="/ristoranti"
      />
      <div style={{ backgroundColor: '#f2f2f2' }}>
        <Card
          css={`
            height: 200px;
            background-size: cover;
            background-image: url(${imgLugano});
            @media only screen and (min-width: 576px) {
              height: 350px;
            }
          `}
          backgroundPosition="center"
          width={1}
          py={3}
        >
          <Flex
            paddingTop={[1, 6]}
            style={{ height: '100%' }}
            flexDirection="column"
            justifyContent="center"
          >
            <Box mb={4} alignSelf="center">
              <Heading
                css={`
                  text-shadow: 2px 1px 6px #111;
                  @media only screen and (min-width: 576px) {
                    text-shadow: 3px 2px 8px #333;
                  }
                `}
                textAlign="center"
                color="#fff"
                fontSize={[5, 6]}
              >
                Luganese e Mendrisiotto.
              </Heading>
            </Box>
            <Flex
              flexDirection="row"
              justifyContent="center"
              width={[1]}
              p={2}
              px={[1, 4]}
            >
              <Button
                mx={2}
                disabled={loading}
                onClick={() => setSortBy(0)}
                variant={sortBy === 0 ? 'primary' : 'secondary'}
              >
                A-Z
              </Button>
              <LocateMeButton
                mx={2}
                disabled={loading}
                onClick={() => {
                  locateUser();
                  setSortBy(1);
                }}
                variant={sortBy === 1 ? 'primary' : 'secondary'}
                loading={loading}
              />
            </Flex>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Flex flexDirection="row" alignItems="center" width={(1, 1 / 2)} p={2} px={4}>
                <Box style={{ position: 'relative' }} width={[200, 300, 380]} px={[1, 2]}>
                  <input
                    placeholder="Cerca un indirizzo"
                    value={address}
                    style={{
                      width: '100%',
                      height: 36,
                      color: Colors.BLACK,
                      padding: '0 18px',
                      borderRadius: 36,
                      backgroundColor: '#fff',
                      border: `2px solid ${Colors.RED}`,
                      boxSizing: 'border-box',
                    }}
                    // onKeyPress={(e) => {
                    //   if (e.key === 'Enter') {
                    //     navigate(`/search/?query=${e.target.value}`);
                    //   }
                    // }}
                    onChange={e => setAddress(e.target.value)}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  >
                    <Button
                      width={80}
                      variant="primary"
                      style={{
                        height: 36,
                        marginTop: 0,
                        borderTopRightRadius: 36,
                        borderBottomRightRadius: 36,
                      }}
                    >
                      Cerca
                    </Button>
                  </div>
                </Box>
              </Flex> */}
            </Flex>
          </Flex>
        </Card>
        {/* {isHoliday && ( */}
        <Flex justifyContent="center" alignItems="center">
          <Box
            mt={3}
            p={3}
            bg="white"
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: Colors.ORANGE,
              borderRadius: 4,
            }}
          >
            <Flex>
              <AiOutlineWarning
                size={22}
                color={Colors.ORANGE}
                style={{ marginRight: 5 }}
              />
              <Text color={Colors.ORANGE} fontSize={3} textAlign="center">
                Periodo di riapertura: gli orari potrebbero variare
              </Text>
            </Flex>
          </Box>
        </Flex>
        {/* )} */}
        <div
          style={{
            maxWidth: 1200,
            margin: '0 auto',
          }}
        >
          <Flex flexDirection="row" flexWrap="wrap" mx={[0, 0, 0, -2]} py={2}>
            {sortedRestaurants.map(restaurant => (
              <Box key={restaurant.id} width={[1, 1, 1 / 2, 1 / 3]}>
                <Box
                  style={{ border: '1px solid #eee', borderRadius: 6 }}
                  bg="#fff"
                  px={[2, 3]}
                  mx={[2]}
                  my={2}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/ristoranti/${slugify(
                      restaurant.name.toLowerCase(),
                    )}/`}
                  >
                    <Card py={3}>
                      <Flex flexDirection="row" alignItems="center">
                        <RestaurantLogo size={60} imageUrl={restaurant.logo} />
                        <Flex
                          mx={10}
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <Text
                            lineHeight={1.4}
                            color={Colors.BLACK}
                            fontSize={3}
                          >
                            {restaurant.name}
                          </Text>
                          <Text
                            lineHeight={1.4}
                            color={Colors.GREY}
                            fontSize={[1, 2]}
                          >
                            {restaurant.cuisineType}
                          </Text>
                          <Flex flexDirection="row">
                            {restaurant.distance > 0 && (
                              <>
                                <Distance
                                  restaurantLocation={restaurant.location}
                                />
                                <Text
                                  lineHeight={1.4}
                                  color={Colors.GREY}
                                  mr={1}
                                >
                                  -
                                </Text>
                              </>
                            )}
                            <RestaurantOpenClose
                              withIcon={false}
                              tradingHours={restaurant.tradingHours}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>
                  </Link>
                </Box>
              </Box>
            ))}
          </Flex>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    mangiami {
      listRestaurants(city: lugano) {
        id
        name
        location {
          coordinates
        }
        cuisineType
        logo
        tradingHours
      }
    }
  }
`;

export default RestaurantsPage;
