import React from 'react';
import { Button } from 'rebass';
import { FaSync, FaLocationArrow } from 'react-icons/fa';

const LocateMeButton = ({ loading, ...rest }) => {
  return (
    <Button {...rest}>
      {loading ? (
        <FaSync size={16} style={{ marginBottom: -2, marginRight: 8 }} className="icon-spin" />
      ) : (
        <FaLocationArrow size={16} style={{ marginBottom: -2, marginRight: 8 }} />
      )}
      Localizzami
    </Button>
  );
};

export default LocateMeButton;
