import { useState, useContext } from 'react';
import UserLocationContext from '../context/UserLocationContext';

const useLocateMe = () => {
  const { setUserLocation } = useContext(UserLocationContext);
  const [loading, setLoading] = useState(false);
  const locateUser = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setUserLocation({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  };
  return [loading, locateUser];
};

export default useLocateMe;
